<template>
    <div class="relative w-full h-[500px] overflow-hidden border-neutral-800 border-b border-t" v-if="showToUser">
      <div v-if="!isLoading">
        <Swiper
          :modules="[Autoplay, EffectCreative]"
          :slides-per-view="1"
          :loop="true"
          :autoplay="{
            delay: 8000,
            disableOnInteraction: true
          }"
          :creative-effect="{
            prev: {
              shadow: false,
              translate: ['-20%', 0, -1]
            },
            next: {
              translate: ['100%', 0, 0]
            }
          }"
          class="absolute inset-0"
          @slideChange="onSlideChange"
        >
          <SwiperSlide
            v-for="(recommendation, idx) in formattedRecommendations"
            :key="idx"
            class="bg-cover bg-center p-4 md:p-20 h-screen overflow-hidden"
            :style="{ backgroundImage: `url(${recommendation.artistHero || 'https://assets.aphex.live/branding/defaults/sets/DefaultNoImageMSE.png'})` }"
          >
            <div class="absolute inset-0 bg-black bg-opacity-85"></div>
            <div class="absolute inset-0 bg-opacity-75  "></div>
  
            <div class="relative z-10 flex flex-col items-center justify-between h-full max-w-7xl mx-auto px-4 py-8 md:px-12">
              <div class="w-full flex flex-col md:flex-row items-center md:items-start">
                <div class="md:hidden mb-4">
                  <img
                    :src="recommendation.artistPicture || 'https://cdn.trackrr.cc/images/public/default-artwork-black.png'"
                    :alt="recommendation.artistName"
                    class="w-48 h-48 object-cover rounded-lg border-4 border-white/50 shadow-lg"
                  />
                </div>
  
                <div class="text-center md:text-left w-full md:w-2/3">
                  <QueryableHref
                    :query="recommendation.slug"
                    :type="recommendation.type"
                    :display-text="recommendation.artistName"
                    :do-not-create-slug="true"
                  >
                    <h1 class="text-4xl md:text-6xl font-bold tracking-tight text-white mb-4">
                      {{ recommendation.artistName }}
                    </h1>
                  </QueryableHref>
                  <h1 class="text-2xl md:text-3xl text-white/50 mb-4">
                    {{ recommendation.type }}
                  </h1>
                  <p v-if="recommendation.type !== 'artist'" class="mt-4 text-white invisible md:visible">
                    {{ recommendation.artistDescription }}
                  </p>
                </div>
  
                <div class="hidden md:block mt-8 md:mt-0 md:ml-auto">
                  <img
                    :src="recommendation.artistPicture || 'https://cdn.trackrr.cc/images/public/default-artwork-black.png'"
                    :alt="recommendation.artistName"
                    class="w-64 h-64 object-cover rounded-lg shadow-2xl"
                  />
                </div>
              </div>
  
              <div class="flex flex-wrap justify-center md:justify-start gap-2 mt-4 w-full">
                <QueryableHref
                  v-for="genre in recommendation.artistGenres"
                  :key="genre.slug"
                  :query="genre.slug"
                  :type="genre.type"
                  :do-not-create-slug="true"
                >
                  <span class="px-3 py-1 bg-white/10 text-white rounded-lg text-sm md:text-base font-semibold hover:cursor-pointer">
                    {{ genre.name }}
                  </span>
                </QueryableHref>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div v-else class="flex justify-center items-center h-full">
        <Loading />
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import { Autoplay, EffectCreative } from 'swiper/modules'
  import { tryOnMounted } from "@vueuse/core"
  import 'swiper/css'
  import 'swiper/css/effect-creative'
  
  const isLoading = ref(true)
  const showToUser = ref(true)
  const recommendations = ref([])
  const currentIndex = ref(0)
  
  const formattedRecommendations = computed(() => {
    return recommendations.value
      .map((recommendation) => {
        if (recommendation.artist) {
          const artistGenres = recommendation.artist.artist_affiliations || []
          return {
            type: "artist",
            artistName: recommendation.artist.name || 'Unknown Artist',
            slug: recommendation.artist.slug || '',
            artistPicture: recommendation.artist.image_url || null,
            artistHero: recommendation.artist.image_url || null,
            artistGenres: artistGenres
              .filter((affiliation) => affiliation && affiliation.tags && affiliation.tags.type === 'genre')
              .map((affiliation) => ({
                type: affiliation.tags.type || 'genre',
                slug: affiliation.tags.slug || '',
                name: affiliation.tags.name || 'Unknown Genre'
              }))
          }
        } else if (recommendation.tag) {
          return {
            type: recommendation.tag.type || 'Unknown Type',
            slug: recommendation.tag.type === 'genre' ? recommendation.tag.id : (recommendation.tag.slug || ''),
            artistName: recommendation.tag.name || 'Unknown Name',
            artistPicture: recommendation.tag.image || null,
            artistHero: recommendation.tag.hero_image || null,
            artistDescription: recommendation.tag.description || '',
            artistGenres: []
          }
        }
        return null
      })
      .filter(recommendation => recommendation !== null)
      .sort((a, b) => {
        if (a.type === 'artist' && b.type !== 'artist') return -1
        if (a.type !== 'artist' && b.type === 'artist') return 1
        if (a.type === 'promoter' && b.type !== 'promoter') return -1
        if (a.type !== 'promoter' && b.type === 'promoter') return 1
        return 0
      })
  })
  
  const onSlideChange = (swiper) => {
    currentIndex.value = swiper.realIndex
  }
  
  tryOnMounted(async () => {
    try {
      const response = await supabaseFetch('/user/explore/recommendations')
      if (response.ok) {
        const data = await response.json()
        recommendations.value = Array.isArray(data) ? data : []
        isLoading.value = false
        console.log("[aphex explore] recommendations", "=>", formattedRecommendations.value)
      } else {
        throw new Error('Failed to fetch recommendations')
      }
    } catch (error) {
      console.error("Error fetching recommendations:", error)
      showToUser.value = false
    } finally {
      if (formattedRecommendations.value.length === 0) {
        showToUser.value = false
      }
    }
  })
  </script>