<template>
  <div>
    <NuxtLayout>
      <template #header>
        <ExploreHomepageSlide />
      </template>
      <template #content>
        <TabsRoot v-model="activeTab" class="w-full">
          <TabsList class="flex flex-wrap sm:flex-nowrap justify-center sm:justify-start gap-8 md:gap-10 mb-6 sm:mb-10">
            <TabsTrigger v-for="tab in availableTabs" :key="tab" :value="tab" class="focus:outline-none">
              <h1 :class="[
          'font-bold tracking-tight transition-colors',
          activeTab === tab ? 'text-white text-3xl sm:text-5xl' : 'text-white/40 text-xl sm:text-3xl hover:text-white/50'
        ]">
                <span>{{ tab }}</span>
              </h1>
            </TabsTrigger>
          </TabsList>
          <div class="border-b border-white/25 w-full mt-5 mb-6 sm:mb-10"></div>
          <TabsContent value="trending">
            <Transition enter-active-class="transition-opacity duration-300" enter-from-class="opacity-0"
              enter-to-class="opacity-100">
              <div v-if="status === 'success'" class="flex flex-col gap-4">
                <div v-for="set in data?.sets" :key="set.id">
                  <IndivSetExplore :setInformation="set" />
                </div>
                <!-- Pagination controls -->
                <div class="flex justify-center mt-8 gap-2">
                  <button v-for="pageNum in totalPages" :key="pageNum" @click="changePage(pageNum)" :class="[
          'px-3 py-2 rounded',
          currentPage === pageNum ? 'bg-white/5 hover:bg-white/10 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
        ]">
                    {{ pageNum }}
                  </button>
                </div>
              </div>
              <div v-else class="flex justify-center items-center h-64">
                <Loading />
              </div>
            </Transition>
          </TabsContent>
          <TabsContent value="history" v-if="authToken">
            <ExploreHistory :access-token="authToken" />
          </TabsContent>
          <TabsContent value="likes" v-if="authToken">
            <LikesHistory :access-token="authToken" />
          </TabsContent>
        </TabsRoot>
      </template>
    </NuxtLayout>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import { onKeyStroke } from '@vueuse/core';
import { TabsContent, TabsList, TabsRoot, TabsTrigger } from 'radix-vue'

const runtimeConfig = useRuntimeConfig();
const route = useRoute();
const router = useRouter();

const searchQuery = ref('');
const searchType = ref('artist');
const isSearchActive = ref(false);

const currentPage = ref(parseInt(route.query.page) || 1);
const itemsPerPage = 10; // This should match the backend default

const data = ref(null);
const status = ref('loading');

const activeTab = ref('trending');
const supabase = useSupabaseClient()
let authToken = ref(null)




const availableTabs = computed(() => {
  if (!authToken.value) {
    return ['trending']
  }


  return ['trending', 'history', 'likes']
})

const fetchTrendingData = async () => {
  status.value = 'loading';
  try {
    const response = await supabaseFetch(`${runtimeConfig.public.backend}/user/explore/sets?page=${currentPage.value}&items_per_page=${itemsPerPage}`);
    const fetchedData = await response.json();
    data.value = fetchedData;
    status.value = 'success';
  } catch (error) {
    console.error("Error fetching trending data:", error);
    status.value = 'error';
  }
};

onMounted(() => {
  if (activeTab.value === 'trending') {
    fetchTrendingData();
  }

  // check if the user is logged in
  supabase.auth.getSession().then(({ data: { session } }) => {
    if (session) {
      console.log("user is logged in");
      authToken.value = session.access_token;
    } else {
      console.log("user is not logged in");
    }
  });



});

const totalPages = computed(() => {
  if (data.value && data.value.total_count) {
    return Math.ceil(data.value.total_count / itemsPerPage);
  }
  return 1;
});

const changePage = (newPage) => {
  currentPage.value = newPage;
  router.push({ query: { ...route.query, page: newPage } });
};

watch(() => route.query.page, async (newPage) => {
  if (activeTab.value === 'trending') {
    currentPage.value = parseInt(newPage) || 1;
    await fetchTrendingData();
  }
});

watch(activeTab, async (newTab) => {
  if (newTab === 'trending') {
    currentPage.value = 1;
    await fetchTrendingData();
  }
});

function convertToSlug(str) {
  return str.toLowerCase().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-');
}

const toggleSearch = () => {
  isSearchActive.value = !isSearchActive.value;
  if (!isSearchActive.value) {
    // Reset search when closing
    searchQuery.value = '';
    searchType.value = 'artist';
    if (activeTab.value === 'trending') {
      fetchTrendingData();
    }
  }
};

const performSearch = async () => {
  if (searchQuery.value.trim()) {
    router.push(`/search/${searchType.value}/${convertToSlug(searchQuery.value)}`);
  }
};

useHead({
  title: 'explore',
  meta: [
    {
      name: 'description',
      content: 'explore and stream the latest sets from your favorite DJs for free on aphex.',
    },
    {
      name: 'og:image',
      content: 'https://cdn.trackrr.cc/images/public/logo.png',
    },
  ],
});

watch(data, () => {
  console.log("sets: ", data.value);
});
</script>

<style lang="scss" scoped></style>